
import { defineComponent, ref } from 'vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { additionalSettingsFromOptions, assigneeOptions, senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import type { FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmPerson,
    TmFormLine,
  },
  setup() {
    const selectAssignee = ref(null)

    const select = ref('')
    const selectNotClearable = ref('')
    const selectOptions = ref(['HTML', 'CSS', 'JS', 'Vue.js'])

    const selectValidated = ref('')
    const selectValidatedOptions = ref(['Error', 'Warning', 'Success'])

    const country = ref<FlagCountry>({ name: 'United Kingdom', id: 'uk' })

    const additionalSettingsFrom = ref(additionalSettingsFromOptions[0])
    const senderSettings = ref(senderSettingsOptions[0])

    return {
      phoneCountryVariant,
      assigneeOptions,
      additionalSettingsFromOptions,
      senderSettingsOptions,
      select,
      selectNotClearable,
      selectValidated,
      selectValidatedOptions,
      selectOptions,
      selectAssignee,
      country,
      additionalSettingsFrom,
      senderSettings,
    }
  },
})
